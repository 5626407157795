<template>
	<div class="training_speaking">
		<div class="traing_item">
			<dl>
				<dt>
					<img
						src="https://tcdn.papaen.com/assets/mini/papaspeaking1v1.png"
						alt=""
					/>
				</dt>
				<dd>
					<h5>外教1v1口语陪练模考</h5>
					<p>
						真人外教1v1视频口语练习或模考，并将基于考试官方评分规则给出口语专业提升建议，
						冲分必备！
					</p>
				</dd>
			</dl>
			<el-button @click="viewDetail()">查看详情</el-button>
		</div>
	</div>
</template>

<script>
import TrainingSpeakingJs from './TrainingSpeaking.js';

export default TrainingSpeakingJs;
</script>

<style lang="less" scoped>
.training_speaking {
	padding: 30px;
	.traing_item {
		width: 100%;
		height: 99px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 15px, 0;
		dl {
			display: flex;
			dt {
				img {
					width: 176px;
					height: 99px;
				}
			}
			dd {
				margin-left: 19px;
				h5 {
					font-size: 18px;
					font-family: PingFang SC;
					font-weight: bold;
					color: #333333;
					margin: 0;
					text-align: left;
				}
				p {
					font-size: 14px;
					font-family: PingFang SC;
					font-weight: 400;
					color: #666666;
					margin: 0;
					display: flex;
					justify-content: left;
					text-align: left;
					margin-top: 8px;
					margin-right: 20px;
				}
			}
		}
		.el-button {
			width: 100px;
			height: 40px;
			margin-right: 30px;
			color: #29d087;
			border-color: #29d087;
		}
	}
}
</style>
