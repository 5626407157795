import * as Api from '@/api/trainingSpeaking/trainingSpeaking';
import { configInit } from '@/api/common/common'
export default {
    name: 'courseReplay',
    data() {
        return {
            booked_count: '',
        };
    },
    created() {
        this.getAccountInfo()
        this.getConfigInit()
    },
    computed: {},
    methods: {
        getAccountInfo() {
            Api.myAccountInfo('', (res) => {
                console.log(res)
                this.booked_count = res.booked_count;
                localStorage.setItem('accountInfo', JSON.stringify(res));
                localStorage.setItem('remaining_count', res.remaining_count);
            });
        },
        viewDetail() {
            this.$router.push({
                name: 'trainingSpeakingMain',
            });
        },
        // 获取configInit数据
        getConfigInit () {
            configInit('', ( res ) => {
            localStorage.setItem('configInit', JSON.stringify(res))
            })
        },
        },
};
